<template>
  <!-- <div> -->
    
    <v-card class="order">
      <v-card-title
        class="px-2 py-1"
      >
        <span class="headline" style="margin: 0 auto;">Заказ №{{ order.id }}</span>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text
        class="px-2 pt-3"
      >
      <v-alert
        :value="successMessage"
        type="success"
        class="my-0"
      >Спасибо вам!</v-alert>
        <p class="mb-2">Дата: {{ formattedOrderDate }} ({{ order.delivery_time }})</p>
        <p class="mb-2">Адрес:
          {{ order.address }},
          кв. {{ order.flat }},
          подъезд {{ order.entrance }},
          этаж {{ order.floor }}
        </p>
        <a 
            :href="`https://yandex.ru/maps/?text=${encodeURIComponent(order.address)},+кв.+${order.flat},+подъезд+${order.entrance},+этаж+${order.floor}`" 
            target="_blank" 
            style="display: inline-block; padding: 10px 15px; background-color: #007bff; color: white; text-decoration: none; border-radius: 5px;">
            Открыть на карте
        </a>

        <v-btn
          color="info"
          v-if="(order.bank_payments && !order.bank_payments.status || !order.already_paid) && !isExistingBankUrl"
          @click="bankURL"
          :loading="btnLoad"
        >Оплатить</v-btn>

        <v-btn
          color="info"
          v-if="order.bank_payments && isExistingBankUrl && !order.bank_payments.status && qrCodeUrl && !order.already_paid"
          @click="bankRemain"
          target="_blank"
          :loading="btnLoad"
        >{{ timeDifference }} remaining</v-btn>
        <template>
          <div>
            <!-- Display the countdown timer -->
            <div v-if="isWithinTwentyMinutes">
              <p>Time since order created: <span>{{ timeDifference }}</span></p>
            </div>
            <div v-else>
              <p>More than 20 minutes have passed.</p>
            </div>
          </div>
        </template>

        <v-alert
          :value="deliveredSuccess"
          type="success"
          class="my-0"
        >Статус заказа изменен</v-alert>
        <v-alert
          :value="deliveredError"
          type="error"
          class="my-0"
        >Ошибка</v-alert>
        <p v-if="order.incognito" class="mb-2">Телефон получателя:
          <a v-if="order.addressee_phone" :href="'tel:' + order.addressee_phone">{{ order.addressee_phone }} ({{order.addressee_name}})</a>
          <span v-else>Номер телефона получателя не доступен.</span>
        </p>
        <div v-else>
          <p class="mb-2">Телефон клиента:
            <a v-if="order.client_phone" :href="'tel:' + order.client_phone">{{ order.client_phone }}  ({{order.client_name}})</a>
            <span v-else>Нет номера телефона клиента</span>
          </p>
          <p class="mb-2">Телефон получателя:
            <a v-if="order.addressee_phone" :href="'tel:' + order.addressee_phone">{{ order.addressee_phone }}  ({{order.addressee_name}})</a>
            <span v-else>Номер телефона получателя не доступен.</span>
          </p>
        </div>
        <v-form
          ref="form"
          lazy-validation
          v-if="order.order_status_id === 3"
        >
          <h3 class="mb-0 mt-4 text-xs-center">Комментарий</h3>
          <v-textarea
            label="Комментарий"
            auto-grow
            v-model="comment"
            row-height="24"
            hide-details
            class="mb-4"
          ></v-textarea>
        </v-form>
      </v-card-text>
      <v-card-actions
        class="px-4 py-2"
      >
        <v-btn
          @click="cancel()"
        >Отмена</v-btn>
        <v-spacer></v-spacer>
        <v-btn
          color="info"
          @click="submitForm"
          v-if="order.order_status_id === 3"
          :loading="btnLoad"
        >Доставил</v-btn>
        <v-btn
          color="info"
          @click="takeDelivery"
          v-else
        >Взять на доставку</v-btn>
      </v-card-actions>
      <v-dialog
        :value="dialogForm"
        :max-width="420"
        persistent
      >
        <template v-if="dialogForm">
            <qrModal 
            v-if="showQr" 
            :bank="bank"
            :order ="order"
            @alreadyPayment="handleAlreadyPayment"
            @cancel="closeDialog()"></qrModal>
        </template>
      </v-dialog>
    </v-card>
  <!-- </div> -->
</template>

<script>
import axios from 'axios';
import qrModal from './qrModal.vue';

export default {
  components: {
    qrModal,
  },
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      comment: '',
      deliveredSuccess: false,
      deliveredError: false,
      editedItem: {},
      btnLoad: false,
      bankOrderId: null, // To store the bank order ID
      orderStatus: null, // To store the order status
      statusInterval: null, // To store the interval ID for polling
      successMessage:false,
      timeDifference: '', // To store the formatted time difference (mm:ss)
      isWithinTwentyMinutes: true, // To check if the time difference is less than 20 minutes
      timerInterval: null, // To store the interval for updating the timer
      isExistingBankUrl: false,
      qrCodeUrl: null,
      dialogForm: false,
      showQr: false,
      bank:this.order.bank_payments,
    };
  },
  computed: {
    formattedOrderDate() {
      if (!this.order.date) return '';

      const date = new Date(this.order.date);
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');

      return `${day}-${month} ${hours}:${minutes}`;
    }
  },
  methods: {
    handleAlreadyPayment(value) {
      if (value) {
        this.order.already_paid = value;
        console.log("Payment already completed and canceled.", value);
        // Handle the payment status here
      }
    },
    closeDialog() {
      this.dialogForm = false;
      this.showQr = false;
    },
    bankRemain(){
      this.dialogForm = true;
      this.showQr = true;
      console.log('bank',this.bank)
    },
    calculateTimeDifference() {
      const createdAt = new Date(this.order.bank_payments.created_at);

        // Get the current UTC time explicitly
        const currentTime = new Date(); // Current time in local timezone
        const currentTimeUTC = currentTime.getTime(); // Convert to UTC milliseconds

        // Get UTC time in milliseconds for `createdAt`
        const createdAtUTC = createdAt.getTime();

        // Calculate the difference in milliseconds
        const diffInMs = currentTimeUTC - createdAtUTC;

        // Convert the difference to seconds
        const diffInSeconds = Math.floor(diffInMs / 1000);

      // Check if the difference is less than 20 minutes (1200 seconds)
      if (diffInSeconds < 1200 && diffInSeconds >= 0) {
        // Calculate the remaining time from 20 minutes
        const remainingSeconds = 1200 - diffInSeconds; // 1200 seconds = 20 minutes

        // Convert remaining seconds to minutes and seconds
        const minutes = Math.floor(remainingSeconds / 60).toString().padStart(2, '0');
        const seconds = (remainingSeconds % 60).toString().padStart(2, '0');
        this.isExistingBankUrl =true;
        this.qrCodeUrl = this.order.bank_payments.bank_url
        // Display the remaining time
        this.timeDifference = `${minutes}:${seconds}`; // Update the formatted time
      } else if (diffInSeconds >= 1200) {
        this.isWithinTwentyMinutes = false; // Stop updating if more than 20 minutes
        this.isExistingBankUrl =false;
        clearInterval(this.timerInterval); // Clear the interval
      } else {
        // Handle negative time differences (e.g., if server time is ahead of created_at)
        this.timeDifference = null;
        this.isExistingBankUrl =false;
      }
    },
    takeDelivery() {
      this.deliveredSuccess = false;
      this.deliveredError = false;
      const id = parseInt(localStorage.getItem("user-id"));
      const userGroup = this.$store.getters.getAuthUserGroup;
      var courier_id = 0
      if (userGroup.code == 'courier' || userGroup.code == 'deliveryman' ) {
        courier_id = id
      } 

      const propsItem = {
        order_id: this.editedItem.id,
        order_status_id: 3,
        delivery_comment: '',
        courier_id: courier_id,
      };

      const url = `${process.env.VUE_APP_DEV_SERVER_PROXY}/orders-status`;

      axios
        .post(url, propsItem)
        .then(() => {
          this.deliveredSuccess = true;

          setTimeout(() => {
            this.$emit('cancel');
          }, 1000);
        })
        .catch((error) => {
          this.deliveredError = true;
          console.log(error);
        });
    },
    bankURL() {
      const url = `${process.env.VUE_APP_DEV_SERVER_PROXY}/payments-qr-bank`;
      const propsItem = {
        amount: parseFloat(this.order.cost),
        order_id: parseInt(this.order.id),
        page_type: 'deliveries',
      };
      
      axios
        .post(url, propsItem)
        .then((response) => {
         const item = response.data;
         console.log(item)
         if (item.payload) {
            // this.bankOrderId = item.orderId
            this.qrCodeUrl = item.payload;
            this.isExistingBankUrl = true;
            this.order.bank_payments = item.bank
            this.bank = item.bank
            console.log(this.order.bank_payments)
            if (this.order.bank_payments && this.order.bank_payments.created_at) {
              this.calculateTimeDifference(); // Initial calculation
              // Set up an interval to update the timer every second
              this.timerInterval = setInterval(this.calculateTimeDifference, 1000);
            }
            console.log(this.qrCodeUrl)
            // window.open(item.qrCodeUrl, '_blank'); // Opens the URL in a new tab
            // if (this.bankOrderId) {
            //   this.startPollingStatus(this.bankOrderId);
            // }
            this.bank = item.bank
            this.dialogForm = true;
            this.showQr = true;
         } else {
            this.isExistingBankUrl = false;
            this.qrCodeUrl = null;
            this.dialogForm = fasle;
            this.showQr = false;
            console.error('qrCodeUrl not found in response');
         }
        })
    },
    // startPollingStatus(orderId) {
    //   // Clear any existing interval to avoid duplicates
    //   if (this.statusInterval) {
    //     clearInterval(this.statusInterval);
    //   }

    //   // Set up polling every 10 seconds
    //   this.statusInterval = setInterval(() => {
    //     this.getStatus(orderId);
    //   }, 10000); // 10 seconds
    // },
    // getStatus(orderId) {
    //   const url = `${process.env.VUE_APP_DEV_SERVER_PROXY}/payments-get-bank-status`;
    //   const propsItem = {
    //     bank_order_id: this.bankOrderId,
    //   };
    //   axios
    //     .post(url, propsItem)
    //     .then((response) => {
    //       const status = response.data.orderStatus;
    //       const paymentStatus = response.data.paymentAmountInfo.paymentState
    //       console.log(`Order ${orderId} status:`, status);

    //       // Stop polling if the order is completed or canceled
    //       if (status == 2 || paymentStatus == 'DEPOSITED') {
    //         clearInterval(this.statusInterval);
    //         this.statusInterval = null; // Reset the interval
    //         this.successMessage = true;
    //         console.log(`Polling stopped for order ${orderId}`);
    //       }
    //     })
    //     .catch((error) => {
    //       console.error(`Error fetching status for order ${orderId}:`, error);
    //     });
    // },
    submitForm() {
      this.deliveredSuccess = false;
      this.deliveredError = false;
     
      const id = parseInt(localStorage.getItem("user-id"));
      const userGroup = this.$store.getters.getAuthUserGroup;
      var courier_id = 0
      if (userGroup.code == 'courier' || userGroup.code == 'deliveryman' ) {
        courier_id = id
      } 

      const propsItem = {
        order_id: this.editedItem.id,
        order_status_id: 4,
        delivery_comment: '',
        courier_id: courier_id,
      };

      const url = `${process.env.VUE_APP_DEV_SERVER_PROXY}/orders-status`;

      axios
        .post(url, propsItem)
        .then(() => {
          this.deliveredSuccess = true;

          setTimeout(() => {
            this.$emit('cancel');
          }, 1000);
        })
        .catch((error) => {
          this.deliveredError = true;
          console.log(error);
        });

      // const validate = this.$refs.form.validate();
      // if (validate) {
      //   this.deliveredSuccess = false;
      //   this.deliveredError = false;

      //   const itemParams = {
      //     type: 'api/orders',
      //     id: this.order.id,
      //     props: {
      //       comment: this.comment,
      //     },
      //   };

      //   this.$store.dispatch('courierDelivered', itemParams).then(() => {
      //     this.deliveredSuccess = true;
      //     setTimeout(() => {
      //       this.$emit('cancel');
      //     }, 1000);
      //   }).catch(() => {
      //     this.deliveredError = true;
      //   });
      // }

      this.deliveredSuccess = true;
      setTimeout(() => {
        this.$emit('cancel');
      }, 1000);
    },
    cancel() {
      this.comment = '';
      this.$emit('cancel');
    },
  },
  mounted() {
    const props = Object.assign({}, this.order);
    // props.orderSourceType = (props.orderSourceType) ? props.orderSourceType : [];
    // props.addressee = (props.addressee) ? +props.addressee.id : null;
    // props.client = (props.client) ? +props.client.id : 0;
    // props.courier = (props.courier) ? +props.courier.id : null;
    // props.createdBy = (props.createdBy) ? +props.createdBy.id : 0;
    // props.orderStatus = (props.orderStatus) ? +props.orderStatus.id : 0;
    // props.clientType = (props.clientType) ? +props.clientType.id : 0;
    // props.deliveryType = (props.deliveryType) ? +props.deliveryType.id : 0;
    // props.orderCost = (props.orderCost) ? String(props.orderCost) : '';
    // props.deliveryTimeOfDay = +props.deliveryTimeOfDay;

    // delete props.topLine;
    
    this.editedItem = props;
    if (this.order.bank_payments && this.order.bank_payments.created_at) {
      this.calculateTimeDifference(); // Initial calculation

      // Set up an interval to update the timer every second
      this.timerInterval = setInterval(this.calculateTimeDifference, 1000);
    }
  },
};
</script>
