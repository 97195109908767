<template>
  <div
    @click="clickProp(prop.field)"
    :style="(backgroundGreen) ? 'background-color: rgb(0 128 0 / 30%);' : ''"
  >
    <template v-if="prop.displayName">
      <strong>{{ prop.displayName }}:</strong>
    </template>

    <template v-if="prop.field === 'bouquets'">
      <template v-for="(item, index) in orderPropValue">
        <v-layout
          row
          wrap
          align-center
          :key="`order-${item.order_id}-bouquet-${index}`"
          :style="(item.done) ? 'background-color: rgb(0 128 0 / 30%);' : ''"
        >
          <v-flex
            grow
            class="pr-3"
            @click="editOrderBouquets()"
          >
            {{ item.name }} - {{ item.count }}шт

            <template v-if="item.place">
              ({{ item.place }}) 
            </template>

          </v-flex>

          <v-flex xs1>
            <v-icon
              small
              @click="addCreateBouquet(item)"
            >
              add
            </v-icon>
          </v-flex>
        </v-layout>
        <div style="border-top: 1px dotted #000; margin: 5px 0;"></div>
      </template>
    </template>

    <template v-else-if="prop.field === 'order_source_ids'">
      <template v-if="orderPropValue">
        <template v-for="(val) in orderPropValue">
          {{ (findItem = tsList.find((item) => item.id === val)) ? findItem.name : '' }}
        </template>
      </template>
    </template>

    <template v-else-if="prop.field === 'delivery_type_id'">
      {{ (findItem = deliveryList.find((item) => item.id === orderPropValue)) ? findItem.name : '' }}
    </template>

    <template v-else-if="prop.field === 'store_id'">
      {{ (findItem = deliveryList.find((item) => item.id === orderPropValue)) ? findItem.name : '' }}
    </template>

    <template v-else-if="prop.field === 'times_of_day'">
     {{ (findItem = deliveryTimeOfDayList.find((item) => item.id === orderPropValue)) ? findItem.name : '' }}
    </template>

    <template v-else-if="prop.field === 'client.client_type_id'">
      {{ (findItem = typeClient.find((item) => item.id === orderPropValue)) ? findItem.name : '' }}
    </template>
    
    <template v-else>
      {{ orderPropValue }}
    </template>
  </div>
</template>

<script>
export default {
  name: 'OrderTableRowProp',
  props: {
    prop: {
      type: Object,
      required: true,
    },
    order: {
      type: Object,
      required: true,
    },
    typeClient: {
      type: Array,
      default: () => [],
    },
    deliveryList: {
      type: Array,
      default: () => [],
    },
    tsList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      deliveryTimeOfDayList: [
        {
          name: 'Утро',
          id: 'morning',
        },
        {
          name: 'День',
          id: 'noon',
        },
        {
          name: 'Вечер',
          id: 'evening',
        },
      ],
    };
  },
  computed: {
    propArray() {
      return this.prop.field.split('.');
    },
    orderPropValue() {
      return this.propArray.reduce((val, propName) => {
        const propVal = (val === null) ? this.order[propName] : val[propName];

        // change date type to dd-mm hh:mm
        if (propName === 'created_at' && propVal) {
          const date = new Date(propVal);
          if (isNaN(date)) {
              console.error("Invalid Date", propVal);
              return null; // or some fallback value
          }
          const dd = String(date.getUTCDate()).padStart(2, '0');
          const mm = String(date.getUTCMonth() + 1).padStart(2, '0'); 
          const hours = String(date.getUTCHours()).padStart(2, '0');
          const minutes = String(date.getUTCMinutes()).padStart(2, '0');
          const formattedDate = `${dd}-${mm} ${hours}:${minutes}`;
          return formattedDate;
        }
        
        if (propName === 'date' && propVal) {
            // Use propVal directly, no need to append "Z"
            const date = new Date(propVal);

            if (isNaN(date)) {
                console.error("Invalid Date", propVal);
                return null; // or some fallback value
            }

            const dd = String(date.getUTCDate()).padStart(2, '0');
            const mm = String(date.getUTCMonth() + 1).padStart(2, '0'); 
            const hours = String(date.getUTCHours()).padStart(2, '0');
            const minutes = String(date.getUTCMinutes()).padStart(2, '0');
            const formattedDate = `${dd}-${mm} ${hours}:${minutes}`;
            return formattedDate;
        }
        return propVal;
      }, null);
    },
    backgroundGreen() {
      return (this.prop.field === 'cost' || this.prop.field === 'already_paid') && this.order.already_paid;
    },
  },
  methods: {
    clickProp(field) {
      const editFields = ['order_status.name', 'comment'];

      if (editFields.indexOf(field) !== -1) {
        this.$store.commit('setEditedOrderId', +this.order.id);
        this.$store.commit('setShowDialogOrderEdit', true);

        if (field === 'order_status.name') {
          this.$store.commit('setEditStatus', true);
        }

        if (field === 'comment') {
          this.$store.commit('setEditDescription', true);
        }

        if (field === 'bouquets') {
          this.$store.commit('setEditOrderBouquets', true);
        }
      }
    },
    editOrderBouquets() {
      this.$store.commit('setEditedOrderId', +this.order.id);
      this.$store.commit('setShowDialogOrderEdit', true);
      this.$store.commit('setEditOrderBouquets', true);
    },
    addCreateBouquet(bouquet) {
      let cardsList = JSON.parse(localStorage.getItem('cardsList'));
      cardsList = (cardsList !== null) ? cardsList : [];

      cardsList.push({
        sum: 0,
        success: false,
        props: {
          floristId: 0,
          totalCost: 0,
          decorPercent: 25,
          decorCost: 0,
          deliveryCost: this.order.delivery_cost,
          salePercent: 0,
          sumSale: 0,
          payment: {
            paymentTypeId: 1,
            amount: 0,
            clientId: this.order.client_id,
            description: '',
          },
          prePayment: 0,
          comment: '',
          sumDecorAdditional: 0,
          bouquetCount: (bouquet.count > 0) ? bouquet.count : 1,
          clientId: this.order.client_id,
          orderBouquet: bouquet.id,
          orderId: this.order.id,
        },
        goods: [],
      });

      localStorage.setItem('cardsList', JSON.stringify(cardsList));
      window.open('/', '_blank');
    },
  },
};
</script>
