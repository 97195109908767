<template>
  <tr 
    :class="[
          order.order_status.color,
          order.isTopLine ? 'top-line' : ''
        ]">
    <template v-if="select">
      <td
        style="width: 30px; max-width: 30px; min-width: 30px;"
        class="px-1"
      >
        <v-checkbox
          :value="selected"
          @change="$emit('select', $event)"
          color="primary"
          hide-details
        ></v-checkbox>
      </td>
    </template>

    <template v-for="(col, index) in userSettings">
      <td :key="`order-col-${index}`">
        <template v-for="(prop, propIndex) in col.dataFields">
          <OrderTableRowProp
            :prop="prop"
            :order="order"
            :typeClient="typeClient"
            :deliveryList="deliveryList"
            :tsList="tsList"
            :storeList="storeList"
            :key="`order-prop-${index}-${propIndex}`"
          ></OrderTableRowProp>        
        </template>
      </td>
    </template>

    <template v-if="btn">
      <td
        class="text-xs-right px-1"
        style="width: 130px; max-width: 130px; min-width: 130px;"
      >
        <!-- <v-icon
          left
          @click="createdBouquet(order)"
          
          title="Собрать"
        >
          playlist_add
        </v-icon> -->

        <!-- <v-icon
          left
          @click="editItem(order.id, true)"
          title="Скопировать"
        >
          add_to_photos
        </v-icon> -->
        <v-btn
          icon
          v-if="!order.already_paid && !isExistingBankUrl"
          class="ma-0"
          @click="bankURL"
        >
        <v-icon>payment</v-icon>
        </v-btn>
        <v-btn
          icon
          v-if="!order.already_paid && isExistingBankUrl && formUrl"
          class="ma-0"
          :href="formUrl"
          target="_blank"
        >
        <i class="pi pi-clock" style="font-size: 17px"></i>
        </v-btn>

        <v-btn
          icon
          :to="`/orders/edit/${order.id}/`"
          title="Изменить"
          class="ma-0"
        >
          <v-icon>
            edit
          </v-icon>
        </v-btn>

        <v-btn
          icon
          :to="`/orders/copy/${order.id}/`"
          title="Скопировать"
          class="ma-0"
        >
          <v-icon>
            add_to_photos
          </v-icon>
        </v-btn>

        <v-menu class="ml-1">
          <v-icon
            left
            slot="activator"
            title="Печать"
            :color="
              order.is_printed ? 'teal darken-3' : '123'
            "
          >
            insert_drive_file
          </v-icon>

          <v-list>
            <v-list-tile
              :to="`/print/orders/delivery/${order.id}/`"
              v-if="order.delivery_type_id === 2"
              target="_blank"
            >
              <v-list-tile-title
                >Печать бланка заказа на доставку</v-list-tile-title
              >
            </v-list-tile>

            <v-list-tile
              :to="`/print/orders/florist/${order.id}/`"
              target="_blank"
            >
              <v-list-tile-title
                >Печать бланка флориста</v-list-tile-title
              >
            </v-list-tile>
          </v-list>
        </v-menu>
      </td>
    </template>
  </tr>
</template>

<script>
import OrderTableRowProp from '@/components/OrderTableRowProp.vue';
import axios from 'axios';

export default {
  name: 'OrderTableRow',
  components: {
    OrderTableRowProp,
  },
  props: {
    selected: {
      type: Boolean,
      required: true,
      default: false,
    },
    order: {
      type: Object,
      required: true,
    },
    select: {
      type: Boolean,
      default: true,
    },
    btn: {
      type: Boolean,
      default: true,
    },
    typeClient: {
      type: Array,
      default: () => [],
    },
    deliveryList: {
      type: Array,
      default: () => [],
    },
    tsList: {
      type: Array,
      default: () => [],
    },
    storeList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      timerInterval: null, // To store the interval for updating the timer
      isExistingBankUrl: false,
      formUrl: null,
    };
  },
  computed: {
    userSettings() {
      const settings = this.$store.getters.getAuthUserSettings;
      return (settings) ? JSON.parse(settings) : [];
    },
  },
  mounted() {
    if (this.order.bank_payments && this.order.bank_payments.created_at) {
      // Debugging: Log the initial `created_at` value
      console.log('Initial created_at:', this.order.bank_payments.created_at);

      // Call the function to calculate the time difference
      this.calculateTimeDifference();

      // Set up an interval to update the timer every second
      this.timerInterval = setInterval(() => {
        this.calculateTimeDifference();
      }, 1000);
    }
  },
  methods: {
    createdBouquet: function createdBouquet(item) {
      console.log('order', item)
      let cardsList = JSON.parse(localStorage.getItem("cardsList"));
      cardsList = cardsList !== null ? cardsList : [];
      const index = cardsList.findIndex(card => card.props.order === item.id);
      if (index === -1) {
        cardsList.push({
          sum: 0,
          success: false,
          props: {
            floristId: null,
            orderId: item.id,
            client: item.client,
            clientId: item.client.id,
            decorPercent: 25,
            deliveryCost: item.deliveryCost,
            salePercent: '',
            sumDecorAdditional: 0
          },
          goods: []
        });
      } else {
        cardsList[index].props.clientId = item.client.id;
      }
      localStorage.setItem("cardsList", JSON.stringify(cardsList));
      const routeData = this.$router.resolve({ path: "/", query: { selectOrder: item.id } });
      window.open(routeData.href, '_blank');
    },
    bankURL() {
      const url = `${process.env.VUE_APP_DEV_SERVER_PROXY}/payments-bank`;
      const propsItem = {
        amount: parseFloat(this.order.cost),
        order_id: parseInt(this.order.id),
        page_type: 'orders',
      };
      axios
        .post(url, propsItem)
        .then((response) => {
         const item = response.data;
          if (item.formUrl && item.orderId) {
              this.bankOrderId = item.orderId
              this.formUrl = item.formUrl;
              this.isExistingBankUrl = true;
              this.order.bank_payments = item.bank
              console.log(this.order.bank_payments)
              if (this.order.bank_payments && this.order.bank_payments.created_at) {
                this.calculateTimeDifference(); // Initial calculation
                // Set up an interval to update the timer every second
                this.timerInterval = setInterval(this.calculateTimeDifference, 1000);
              }
              window.open(item.formUrl, '_blank'); // Opens the URL in a new tab
              // if (this.bankOrderId) {
              //   this.startPollingStatus(this.bankOrderId);
              // }
          } else {
              this.isExistingBankUrl = false;
              this.formUrl = null;
              console.error('formUrl not found in response');
          }
        })
    },

    calculateTimeDifference() {
      // Ensure `created_at` is parsed correctly as a UTC date
      const createdAt = new Date(this.order.bank_payments.created_at);

      // Get the current UTC time explicitly
      const currentTime = new Date(); // Current time in local timezone
      const currentTimeUTC = currentTime.getTime(); // Convert to UTC milliseconds

      // Get UTC time in milliseconds for `createdAt`
      const createdAtUTC = createdAt.getTime();

      // Check if `createdAt` is in the future (invalid case)
      if (createdAtUTC > currentTimeUTC) {
        console.error('Error: createdAt is in the future. Adjusting to current time.');
        this.isExistingBankUrl = false;
        clearInterval(this.timerInterval);
        return;
      }

      // Calculate the difference in milliseconds
      const diffInMs = currentTimeUTC - createdAtUTC;

      // Convert the difference to seconds
      const diffInSeconds = Math.floor(diffInMs / 1000);

      // Check if the difference is less than 20 minutes (1200 seconds)
      if (diffInSeconds < 1200 && diffInSeconds >= 0) {
        // Calculate the remaining time from 20 minutes
        const remainingSeconds = 1200 - diffInSeconds; // 1200 seconds = 20 minutes

        // Convert remaining seconds to minutes and seconds
        const minutes = Math.floor(remainingSeconds / 60).toString().padStart(2, '0');
        const seconds = (remainingSeconds % 60).toString().padStart(2, '0');

        this.isExistingBankUrl = true;
        this.formUrl = "https://payment.alfabank.ru/payment/merchants/ecom2/payment_ru.html?mdOrder=" + this.order.bank_payments.bank_order_id;
      } else if (diffInSeconds >= 1200) {
        this.isExistingBankUrl = false;
        clearInterval(this.timerInterval); // Clear the interval
      } else {
        // Handle negative time differences (e.g., if server time is ahead of created_at)
        console.error('Negative time difference detected. Please check server and client time synchronization.');
        this.isExistingBankUrl = false;
      }
    }
  },
};
</script>
