<template>
  <tr>
    <template v-if="select">
      <th
        style="width: 30px; max-width: 30px; min-width: 30px;"
        class="px-1"
      >
        <v-checkbox
          :value="props.all"
          :indeterminate="props.indeterminate"
          @change="$emit('select')"
          color="primary"
          hide-details
        ></v-checkbox>
      </th>
    </template>

    <template v-for="(col, index) in userSettings">
      <th 
        :key="`order-col-${index}`"
        class="px-1 text-xs-left"
        :class="[
          'column sortable',
          (userSort && userSort.descending) ? 'desc' : 'asc',
           col.dataFields[0].field === (userSort && userSort.sortBy) ? 'active' : ''
        ]"
        @click="handleSort(col.dataFields[0].field)"
        :style="col.width ? { width: col.width + 'px' } : {}"
      >
        <v-icon small>arrow_upward</v-icon>
        {{ col.columnName }}    
      </th>
    </template>

    <template v-if="btn">
      <th
        class="text-xs-right px-1"
        style="width: 100px; max-width: 100px; min-width: 100px;"
      ></th>
    </template>
  </tr>
</template>

<script>
export default {
  name: 'OrderTableRowHead',
  props: {
    props: {
      type: Object,
      required: true,
    },
    select: {
      type: Boolean,
      default: true,
    },
    btn: {
      type: Boolean,
      default: true,
    },
    changeSort: {
      type: Function,
      required: true,
    },
    userSort: {
        type: Object,
        required: true,
    },
    pagination: {
        type: Object,
        required: true,
    },
  },
  computed: {
    userSettings() {
      const settings = this.$store.getters.getAuthUserSettings;
      return (settings) ? JSON.parse(settings) : [];
    },
  },
  methods: {
    handleSort(column) {
      this.changeSort(column);
    }
  }
};
</script>
