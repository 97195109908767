<template>
  <v-container
    fluid
    class="pa-0"
  >
    <v-alert :value="errorMessage" type="error" class="my-0">
      {{errorAlert}}
    </v-alert>
    <div class="hidden-sm-and-down">
      <template>
        <v-dialog
          :value="dialogForm"
          :max-width="editStatus || editDescription ? '420px' : '1200px'"
          :fullscreen="editStatus || editDescription ? false : true"
        >
          <template v-if="dialogForm">
            <orderShow
              v-if="showOrder"
              :order="orderSelect"
              @cancel="closeDialog()"
            ></orderShow>

            <mapShow
              v-if="showMap"
              :order="orderSelect"
              @cancel="closeDialog()"
            ></mapShow>
            <edit-bouquets
              v-if="editOrderBouquets"
              :order="orderSelect"
              @cancel="closeDialog()"
            ></edit-bouquets>
            <change-status
              v-if="editStatus"
              :id="editedId"
              :orderSourceType="orderSourceTypeEditElem"
              @cancel="closeDialog()"
            ></change-status>
          </template>
        </v-dialog>

        <v-card v-if="this.widthWindow >= 960">
          <v-layout
            row
            wrap
          >
            <v-flex
              xs7
            >
              <v-card-title>
                <v-layout
                  row
                  wrap
                >
                  <v-flex
                    xs3
                    class="px-2"
                  >
                    <v-select
                      label="Курьер"
                      :items="[{id: '', name: 'Все'}].concat(couriersList)"
                      item-text="name"
                      item-value="id"
                      v-model="filter.courier_id"
                      hide-details
                      @change="customFilter()"
                    ></v-select>
                  </v-flex>
                  <v-flex
                    xs3
                    class="px-2"
                  >
                  <v-select
                    label="Время суток"
                    :items="deliveryTimeOfDayFilter"
                    item-text="name"
                    item-value="id"
                    v-model="filter.times_of_day"
                    hide-details
                    @change="customFilter()"
                  ></v-select>
                  </v-flex>
                  <v-flex
                    xs3
                    class="pl-2"
                  >
                    <v-menu
                      :close-on-content-click="false"
                      v-model="dataStartPicker"
                      :nudge-right="40"
                      lazy
                      transition="scale-transition"
                      offset-y
                      full-width
                      min-width="290px"
                      class="mb-4"
                    >
                      <v-text-field
                        slot="activator"
                        label="Дата доставки (с)"
                        v-model="filter.start_date"
                        prepend-icon="event"
                        hide-details
                        readonly
                      ></v-text-field>
                      <v-date-picker
                        v-model="filter.start_date"
                        @input="dataStartPicker = false"
                        no-title
                        scrollable
                        locale="ru-ru"
                        first-day-of-week="1"
                        :max="!!filter.end_date ? filter.end_date : undefined"
                        @change="customFilter()"
                      ></v-date-picker>
                    </v-menu>
                  </v-flex>
                  <v-flex
                    xs3
                    class="pl-2"
                  >
                    <v-menu
                      :close-on-content-click="false"
                      v-model="dataEndPicker"
                      :nudge-right="40"
                      lazy
                      transition="scale-transition"
                      offset-y
                      full-width
                      min-width="290px"
                      class="mb-4"
                    >
                      <v-text-field
                        slot="activator"
                        label="Дата доставки (по)"
                        v-model="filter.end_date"
                        prepend-icon="event"
                        hide-details
                        readonly
                      ></v-text-field>
                      <v-date-picker
                        v-model="filter.end_date"
                        @input="dataEndPicker = false"
                        no-title
                        locale="ru-ru"
                        scrollable
                        first-day-of-week="1"
                        :min="!!filter.start_date ? filter.start_date : undefined"
                        @change="customFilter()"
                      ></v-date-picker>
                    </v-menu>
                  </v-flex>
                </v-layout>
              </v-card-title>

              <v-layout
                row
                wrap
                align-center
              >
                <v-flex
                  xs12
                  class="px-1"
                >
                  Доставок: {{ ordersList.length }}

                  <!-- <v-btn
                    color="primary"
                    dark
                    @click.prevent="changeSettings()"
                  >Настройки</v-btn> -->
                </v-flex>
              </v-layout>

              <v-data-table
                :headers="headersTable"
                :items="ordersList"
                hide-actions
                no-data-text="Заказов не найдено"
                no-results-text="Заказов не найдено"
                :pagination.sync="pagination"
                class="orders-table"
                item-key="id"
              >
                <!-- <template slot="headers" slot-scope="props">
                  <tr>
                    <th
                      v-for="header in props.headers"
                      class="px-1 text-xs-left"
                      :key="header.text"
                      :class="[
                        'column sortable', pagination.descending ? 'desc' : 'asc',
                        header.value === pagination.sortBy ? 'active' : ''
                      ]"
                      :style="{
                        width: `${header.width}px`,
                        maxWidth: `${header.width}px`,
                        minWidth: `${header.width}px`
                      }"
                      @click="changeSort(header.value)"
                    >
                      <v-icon small>arrow_upward</v-icon>
                      {{ header.text }}
                    </th>
                  </tr>
                </template> -->
                <template slot="items" slot-scope="props">
                  <!-- <tr
                    :class="[props.item.orderStatus.color, (props.item.topLine) ? 'top-line' : '']"
                  > -->
                  <tr :class="[props.item.order_status.color, (props.item.topLine) ? 'top-line' : '']">
                    <td
                      class="px-1"
                      @click.prevent="viewOrder(props.item.id)"
                    >
                      {{ props.item.id }}
                    </td>
                    <td
                      class="px-1"
                      @click.prevent="viewOrder(props.item.id)"
                    >
                      {{ props.item.address }},
                      кв. {{ props.item.flat }},
                      подъезд {{ props.item.entrance }},
                      этаж {{ props.item.floor }},
                    </td>
                    <td
                      class="px-1"
                      @click.prevent="viewOrder(props.item.id)"
                    >
                      {{ props.item.delivery_time }}
                      <br>{{ (props.item.times_of_day) ? deliveryTimeOfDayList.find((elem) => elem.id === props.item.times_of_day).name : ''}}
                    </td>
                    <td
                      class="px-1"
                      @click.prevent="viewOrder(props.item.id)"
                    >
                      <template v-for="(elem, index) in props.item.bouquets">
                        <div
                          :class="elem.isReady ? 'green' : ''"
                          :key="index"
                        >
                          {{ elem.name }} - {{ elem.count }}
                        </div>
                      </template>
                    </td>
                    <td
                      class="px-1"
                      @click.prevent="editBouquets(props.item.id)"
                    >
                      <template v-for="(item, key) in props.item.bouquets">
                        <!-- {{ item.name }} - {{ item.count }} -->
                        <template v-if="item.place">
                          ({{ item.place }})
                          <br :key="key">
                        </template>
                      </template>
                    </td>
                    <td class="px-1 text-xs-center" style="width: 40px;">
                      <v-icon
                        left
                        @click.prevent="zoomOrderMap(props.item.coordinates)"
                        class="mr-0"
                        title="Карта"
                      >
                        my_location
                      </v-icon>
                    </td>
                  </tr>
                </template>
              </v-data-table>

              <!-- <v-layout row wrap justify-space-around class="py-2">
                <v-flex xs2 class="px-3">
                  <v-text-field
                    label="Количество на странице"
                    v-model.number="take"
                    hide-details
                    @change="changeShowElem()"
                  ></v-text-field>
                </v-flex>
                <v-flex xs10 class="text-xs-right px-3">
                  <v-btn
                    small
                    color="info"
                    class="ml-3"
                    :disabled="page === 1"
                    @click="prevPage()"
                  >
                    <v-icon dark>keyboard_arrow_left</v-icon>
                  </v-btn>
                  <v-btn
                    small
                    color="info"
                    class="ml-3"
                    :disabled="ordersList.length < take"
                    @click="nextPage()"
                  >
                    <v-icon dark>keyboard_arrow_right</v-icon>
                  </v-btn>
                </v-flex>
              </v-layout> -->

              <!-- <v-data-table
                :headers="headersTable"
                :items="ordersList"
                hide-actions
                no-data-text="Заказов не найдено"
                no-results-text="Заказов не найдено"
                :pagination.sync="pagination"
                class="orders-table"
                item-key="id"
              > -->
                <!-- <template slot="headers" slot-scope="props">
                  <OrderTableRowHead
                    :props="props"
                    :select="false"
                    :btn="false"
                  ></OrderTableRowHead>
                </template>

                <template slot="items" slot-scope="props">
                  <OrderTableRow
                    :order="props.item"
                    :selected="props.selected"
                    :select="false"
                    :btn="false"
                  ></OrderTableRow>
                </template> -->
                <!-- <template slot="items" slot-scope="props">
                  <tr
                    :class="[props.item.orderStatus.color, (props.item.topLine) ? 'top-line' : '']"
                    @click.prevent="zoomOrderMap(props.item.coordinates)"
                  >
                    <template v-for="(col, colIndex) in colsTable">
                      <td
                        class="px-1"
                        :style="{
                          width: `${col.width}px`,
                          maxWidth: `${col.width}px`,
                          minWidth: `${col.width}px`
                        }"
                        :key="`col-${colIndex}`"
                      >
                        <template v-for="(prop, propIndex) in col.dataFields">
                          <div :key="`prop-${colIndex}-${propIndex}`">
                            <template v-if="prop.displayName">
                              {{ prop.displayName }}:
                            </template>
                            <template v-if="
                              props.item[prop.field]
                              || prop.field === 'incognito'
                              || prop.field === 'description'
                            ">
                              <template v-if="prop.field === 'deliveryTimeOfDay'">
                                {{ deliveryTimeOfDayList[props.item[prop.field]] }}
                              </template>
                              <template v-else-if="prop.field === 'createdBy'">
                                {{ props.item[prop.field].name }}
                              </template>
                              <template v-else-if="prop.field === 'orderSourceType'">
                                <template v-for="(item, index) in props.item[prop.field]">
                                  <template v-if="item">
                                    <br :key="index" v-if="index">{{ item.name }}
                                  </template>
                                </template>
                              </template>
                              <template v-else-if="prop.field === 'incognito'">
                                {{ (props.item[prop.field]) ? 'Да' : 'Нет' }}
                              </template>
                              <template v-else-if="prop.field === 'description'">
                                <div
                                  :style="
                                    (!props.item[prop.field])
                                    ? 'min-height: 20px; box-shadow: 0 0 0 1px rgba(0,0,0,.12);'
                                    : ''
                                  "
                                >
                                  {{ props.item[prop.field] }}
                                </div>
                              </template>
                              <template v-else-if="prop.field === 'bouquets'">
                                <template v-for="(item, key) in props.item[prop.field]">
                                  {{ item.name }} - {{ item.count }}
                                  <template v-if="item.place">
                                    ({{ item.place }})
                                  </template>
                                  <br :key="key">
                                </template>
                              </template>
                              <template v-else-if="prop.field === 'orderStatus'">
                                <div style="display: inline">
                                  {{ props.item[prop.field].name }}
                                </div>
                              </template>
                              <template v-else-if="prop.field === 'deliveryType'">
                                {{ props.item[prop.field].name }}
                              </template>
                              <template v-else-if="prop.field === 'deliveryDate'">
                                {{ props.item[`${prop.field}Str`] }}
                              </template>
                              <template v-else-if="prop.field === 'courier'">
                                {{ props.item[prop.field].name }}
                              </template>
                              <template v-else-if="prop.field === 'clientType'">
                                {{ props.item[prop.field].name }}
                              </template>
                              <template v-else>
                                {{ props.item[prop.field] }}
                              </template>
                            </template>
                          </div>
                        </template>
                      </td>
                    </template>
                  </tr>
                </template>
              </v-data-table> -->
            </v-flex>
            <v-flex
              xs5
            >
              <div style="position: relative; height: 100%; min-height: 600px; overflow: hidden;">
                <yandex-map
                  :coords="coordsMap"
                  :zoom="zoomMap"
                  style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;"
                  :controls="['trafficControl']"
                >
                  <template v-for="(courier, index) in couriersGpsList">
                    <ymap-marker
                      :key="`courier-${index}`"
                      :marker-id="`courier-${courier.id}`"
                      marker-type="placemark"
                      :coords="[courier.gps.y, courier.gps.x]"
                      :icon="{color: 'green', content: courier.name}"
                    ></ymap-marker>
                  </template>

                  <template v-for="(item, index) in placemarks">
                    <ymap-marker
                      :key="`order-${index}`"
                      :marker-id="`order-${item.id}`"
                      marker-type="placemark"
                      :coords="item.coords"
                      :balloon="item.balloon"
                      :icon="{color: 'blue'}"
                      :cluster-name="item.clusterName"
                    ></ymap-marker>
                  </template>
                </yandex-map>
              </div>
            </v-flex>
          </v-layout>
        </v-card>
      </template>
    </div>
    <div class="hidden-md-and-up" v-if="this.widthWindow < 960">
      <div>
        <v-btn
          exact
          @click="setFilterProp('courier_id', $store.getters.getAuthUser)"
          :color="(filter.courier_id !== null) ? 'info' : ''"
          style="min-width: 90px;"
        >
          Мои
        </v-btn>
        <v-btn
          exact
          @click="setFilterProp('courier_id', null)"
          :color="(filter.courier === null) ? 'info' : ''"
          style="min-width: 90px;"
        >
          Все
        </v-btn>
        <v-btn
          @click="logout()"
          exact
          style="min-width: 90px;"
        >
          Выход
        </v-btn>
      </div>
      <v-layout
        row
        wrap
      >
        <v-flex
          xs6
        >
          <v-menu
            :close-on-content-click="false"
            v-model="dataStartPicker"
            :nudge-right="40"
            lazy
            transition="scale-transition"
            offset-y
            full-width
            min-width="290px"
            class="mb-1"
          >
            <v-text-field
              slot="activator"
              label="Дата доставки (с)"
              v-model="filter.start_date"
              prepend-icon="event"
              hide-details
              readonly
            ></v-text-field>
            <v-date-picker
              v-model="filter.start_date"
              @input="dataStartPicker = false"
              no-title
              scrollable
              locale="ru-ru"
              first-day-of-week="1"
              :max="(!!filter.end_date) ? filter.end_date : undefined"
              @change="customFilter()"
            ></v-date-picker>
          </v-menu>
        </v-flex>
        <v-flex
          xs6
        >
          <v-menu
            :close-on-content-click="false"
            v-model="dataEndPicker"
            :nudge-right="40"
            lazy
            transition="scale-transition"
            offset-y
            full-width
            min-width="290px"
            class="mb-1"
          >
            <v-text-field
              slot="activator"
              label="Дата доставки (по)"
              v-model="filter.end_date"
              prepend-icon="event"
              hide-details
              readonly
            ></v-text-field>
            <v-date-picker
              v-model="filter.end_date"
              @input="dataEndPicker = false"
              no-title
              locale="ru-ru"
              scrollable
              first-day-of-week="1"
              :min="(!!filter.start_date) ? filter.start_date : undefined"
              @change="customFilter()"
            ></v-date-picker>
          </v-menu>
        </v-flex>
      </v-layout>
      <div>
        <v-btn
          exact
          @click="setFilterProp('times_of_day', 'morning')"
          :color="(filter.times_of_day === 'morning') ? 'info' : ''"
          class="px-1"
          style="min-width: 64px;"
        >
          Утро
        </v-btn>
        <v-btn
          exact
          @click="setFilterProp('times_of_day', 'noon')"
          :color="(filter.times_of_day === 'noon') ? 'info' : ''"
          class="px-1"
          style="min-width: 64px;"
        >
          День
        </v-btn>
        <v-btn
          exact
          @click="setFilterProp('times_of_day', 'evening')"
          :color="(filter.times_of_day === 'evening') ? 'info' : ''"
          class="px-1"
          style="min-width: 64px;"
        >
          Вечер
        </v-btn>
        <v-btn
          exact
          @click="setFilterProp('times_of_day', null)"
          :color="(filter.times_of_day === null) ? 'info' : ''"
          class="px-1"
          style="min-width: 64px;"
        >
          Все
        </v-btn>
      </div>
      <div>
        <v-btn
          exact
          @click="setFilterProp('delivery_type_id', 2)"
          :color="(filter.delivery_type_id === 2) ? 'info' : ''"
          class="px-1"
          style="min-width: 144px;"
        >
          Доставка
        </v-btn>
        <v-btn
          exact
          @click="setFilterProp('delivery_type_id', '')"
          :color="(filter.delivery_type_id === '') ? 'info' : ''"
          class="px-1"
          style="min-width: 144px;"
        >
          Все
        </v-btn>
      </div>
      <v-data-table
        :headers="headersTableMobile"
        :items="ordersList"
        hide-actions
        no-data-text="Заказов не найдено"
        no-results-text="Заказов не найдено"
        :pagination.sync="pagination"
        class="orders-table"
        item-key="id"
      >
        <!-- <template slot="headers" slot-scope="props">
          <tr>
            <th
              v-for="header in props.headers"
              class="px-1 text-xs-left"
              :key="header.text"
              :class="[
                'column sortable', pagination.descending ? 'desc' : 'asc',
                header.value === pagination.sortBy ? 'active' : ''
              ]"
              :style="{
                width: `${header.width}px`,
                maxWidth: `${header.width}px`,
                minWidth: `${header.width}px`
              }"
              @click="changeSort(header.value)"
            >
              <v-icon small>arrow_upward</v-icon>
              {{ header.text }}
            </th>
          </tr>
        </template> -->
        <template slot="items" slot-scope="props">
          <!-- <tr
            :class="[props.item.orderStatus.color, (props.item.topLine) ? 'top-line' : '']"
          > -->
          <tr  :class="[props.item.order_status.color, (props.item.topLine) ? 'top-line' : '']">
            <td
              class="px-1"
              @click.prevent="viewOrder(props.item.id)"
            >
              {{ props.item.id }}
            </td>
            <td
              class="px-1"
              @click.prevent="viewOrder(props.item.id)"
            >
              {{ props.item.address }},
              кв. {{ props.item.flat }},
              подъезд {{ props.item.entrance }},
              этаж {{ props.item.floor }},
            </td>
            <td
              class="px-1"
              @click.prevent="viewOrder(props.item.id)"
            >
              {{ props.item.delivery_time }}
              <br>{{(props.item.times_of_day) ? deliveryTimeOfDayList.find((elem) => elem.id === props.item.times_of_day).name : ''}}
            </td>
            <td
              class="px-1"
              @click.prevent="viewOrder(props.item.id)"
            >
              <template v-for="(elem, index) in props.item.bouquets">
                <div
                  :class="elem.isReady ? 'green' : ''"
                  :key="index"
                >
                  {{ elem.name }} - {{ elem.count }}
                </div>
              </template>
            </td>
            <td
              class="px-1"
              @click.prevent="editBouquets(props.item.id)"
            >
              <template v-for="(item, key) in props.item.bouquets">
                <!-- {{ item.name }} - {{ item.count }} -->
                <template v-if="item.place">
                  ({{ item.place }})
                  <br :key="key">
                </template>
              </template>
            </td>
            <td class="px-1 text-xs-center" style="width: 40px;">
              <v-icon
                left
                @click.prevent="viewMap(props.item.id)"
                class="mr-0"
                title="Карта"
              >
                my_location
              </v-icon>
            </td>
          </tr>
        </template>
      </v-data-table>
      <!-- <v-layout row wrap justify-space-around class="py-2">
        <v-flex xs2 class="px-3">
          <v-text-field
            label="Количество на странице"
            v-model.number="take"
            hide-details
            @change="changeShowElem()"
          ></v-text-field>
        </v-flex>
        <v-flex xs10 class="text-xs-right px-3">
          <v-btn
            small
            color="info"
            class="ml-3"
            :disabled="page === 1"
            @click="prevPage()"
          >
            <v-icon dark>keyboard_arrow_left</v-icon>
          </v-btn>
          <v-btn
            small
            color="info"
            class="ml-3"
            :disabled="ordersList.length < take"
            @click="nextPage()"
          >
            <v-icon dark>keyboard_arrow_right</v-icon>
          </v-btn>
        </v-flex>
      </v-layout> -->
    </div>
  </v-container>
</template>

<script>
import axios from 'axios';
import OrderTableRow from "@/components/OrderTableRow.vue";
import OrderTableRowHead from "@/components/OrderTableRowHead.vue";
import { yandexMap, ymapMarker } from 'vue-yandex-maps';
import orderShow from './showOrder.vue';
import mapShow from './showMap.vue';
import ChangeStatus from "@/views/Orders/changeStatus.vue";
import editBouquets from './editOrderBouquets.vue';

export default {
  name: 'Deliveries',
  components: {
    OrderTableRow,
    OrderTableRowHead,
    yandexMap,
    ymapMarker,
    orderShow,
    mapShow,
    ChangeStatus,
    editBouquets,
  },
  data() {
    return {
      filter: {
        order_status_id: '',
        times_of_day: null,
        start_date: null,
        end_date: null,
        courier_id: null,
        delivery_type_id: 2,
        order_status_ids: [1,2,3,7],
      },

      loadingData: [
        {
          title: 'Получение заказов',
          error: false,
          loading: true,
          color: 'amber',
          id: 'orders',
        },
      ],
      dataStartPicker: false,
      dataEndPicker: false,
      ordersList: [],
      statusList: [],
      // clientsList: [],
      couriersList: [],
      // typeClient: [],
      dialogForm: false,
      editSettings: false,
      showOrder: false,
      dataNowStr: '',
      deliveryTimeOfDayList: [
        {
          name: 'Утро',
          id: 'morning',
        },
        {
          name: 'День',
          id: 'noon',
        },
        {
          name: 'Вечер',
          id: 'evening',
        },
      ],
      userSettings: [],
      pagination: {
        sortBy: "id",
        rowsPerPage: -1,
        descending: false
      },
      deliveryTimeOfDayFilter: [
        {
          name: 'Все',
          id: '',
        },
        {
          name: 'Утро',
          id: 'morning',
        },
        {
          name: 'День',
          id: 'noon',
        },
        {
          name: 'Вечер',
          id: 'evening',
        },
      ],
      headersTableMobile: [
        {
          text: '№',
          value: 'id',
          sortable: false,
        },
        {
          text: 'Адрес',
          value: 'address',
          sortable: false,
        },
        {
          text: 'Время',
          value: 'deliveryTime',
          sortable: false,
        },
        {
          text: 'Букеты',
          value: 'bouquets',
          sortable: false,
        },
        {
          text: 'Место',
          value: 'place',
          sortable: false,
        },
        {
          text: '',
          value: 'action',
          sortable: false,
        },
      ],
      take: 999,
      page: 1,
      orderSelect: {},
      coordsMap: [53.05, 50.101783],
      zoomMap: 10,
      widthWindow: 0,
      showOrderMap: false,
      showMap: false,
      editOrderBouquets: false,
      skipQuery: false,
      bank_order_id: null,
      headersTable: [
        {
          text: '№',
          value: 'id',
          sortable: false,
        },
        {
          text: 'Адрес',
          value: 'address',
          sortable: false,
        },
        {
          text: 'Время',
          value: 'deliveryTime',
          sortable: false,
        },
        {
          text: 'Букеты',
          value: 'bouquets',
          sortable: false,
        },
        {
          text: 'Место',
          value: 'place',
          sortable: false,
        },
      ],
      errorMessage: false,
      errorAlert: '',
    };
  },
  watch: {
    // dialogForm(newValue) {
    //   if (!newValue) {
    //     this.closeDialog();
    //   }
    // },
    updateOrderList(newValue) {
      if (newValue) {
        this.$store.commit('setUpdateOrderList', false);
        this.getOrdersList();
      }
    },
  },
  computed: {
    editDescription() {
      return this.$store.getters.getEditDescription;
    },
    updateOrderList() {
      return this.$store.getters.getUpdateOrderList;
    },
    couriersGpsList() {
      // return this.$store.getters.getCouriersGps;
      return [];
    },
    placemarks() {
      const placemarks = [];

      this.ordersList.forEach((item) => {
        const coordinates = item.coordinates.split(',');

        if (coordinates && coordinates[0] && coordinates[1]) {
          let timesOfDay = this.deliveryTimeOfDayList.find((elem) => elem.id === item.times_of_day);
          timesOfDay = (timesOfDay) ? timesOfDay.name : '-';

          placemarks.push({
            id: item.id,
            coords: coordinates,
            balloon: {
              header: `${item.date},
                ${item.delivery_time}
                (${timesOfDay})
              `,
              body: `${item.address}`,
              footer: '',
            },
            clusterName: 'orders',
          });
        }
      });

      return placemarks;
    },
    editStatus() {
      return this.$store.getters.getEditStatus;
    },
    // loadingDialog() {
    //   const loadData = this.loadingData.filter(item => !item.error && !item.loading);
    //   return (loadData.length === this.loadingData.length) ? 0 : 1;
    // },
    orderSourceTypeEditElem() {
      const editElem = this.ordersList.find(item => item.id === this.editedId);
      return (editElem) ? editElem.orderSourceType : [];
    },
    // headersTable() {
    //   const cols = this.userSettings.map((item) => {
    //     const elem = {
    //       text: item.columnName,
    //       align: 'left',
    //       value: item.sortField,
    //       width: item.width,
    //     };

    //     return elem;
    //   });

    //   return cols;
    // },
    // colsTable() {
    //   const cols = this.userSettings.map((item) => {
    //     const elem = {
    //       width: item.width,
    //       dataFields: item.dataFields,
    //     };

    //     return elem;
    //   });

    //   return cols;
    // },
  },
  methods: {
    changeShowElem() {
      localStorage.setItem("countElemPage", this.take);
      this.$store.commit("setCountElemPage", this.take);
      this.page = 1;
      this.getOrdersList();
    },
    prevPage() {
      this.page -= 1;
      this.getOrdersList();
    },
    nextPage() {
      this.page += 1;
      this.getOrdersList();
    },
    updateWidthWindow() {
      this.widthWindow = window.innerWidth;
    },
    customFilter() {
      this.page = 1;
      this.getOrdersList();
    },
    clientsFilter(item, queryText) {
      const textOne = item.name.toLowerCase();
      const textTwo = item.phone.replace(/[^0-9]/gim, '');
      const searchText = queryText.toLowerCase();

      return textOne.indexOf(searchText) > -1 ||
        textTwo.indexOf(searchText) > -1;
    },
    getOrdersList() {
      const loadData = this.loadingData.find(item => item.id === 'orders');
      const url = `${process.env.VUE_APP_DEV_SERVER_PROXY}/orders`;

      const propsItem = {
        page: this.page,
        page_limit: this.take,
      };

      Object.keys(this.filter).forEach((key) => {
        if (this.filter[key]) {
          propsItem[key] = this.filter[key];
        }
      });
      
      axios
        .get(url, {
          params: propsItem,
        })
        .then((response) => {
          const items = response.data;
          this.ordersList = items;
          loadData.title = 'Заказы получены!';
          loadData.loading = false;
        })
        .catch((error) => {
          loadData.title = 'Ошибка получения заказов!';
          loadData.error = true;
          console.log(error);
        });
    },
    getStatus() {
      const url = `${process.env.VUE_APP_DEV_SERVER_PROXY}/orders-status`;

      axios
        .get(url)
        .then((response) => {
          this.statusList = response.data;
        })
        .catch((error) => {
          console.log('error', error)
        });
    },
    getClientTypes() {
      const url = `${process.env.VUE_APP_DEV_SERVER_PROXY}/client-types`;

      axios
        .get(url)
        .then((response) => {
          this.typeClient = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getDeliveryTypes() {
      const url = `${process.env.VUE_APP_DEV_SERVER_PROXY}/orders-delivery-types`;

      axios
        .get(url)
        .then((response) => {
          this.deliveryList = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getOrdersSource() {
      const url = `${process.env.VUE_APP_DEV_SERVER_PROXY}/orders-source`;

      axios
        .get(url)
        .then((response) => {
          this.tsList = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getBankStatus() {
      const url = `${process.env.VUE_APP_DEV_SERVER_PROXY}/payments-bank-status`;

      const propsItem = {
        bank_order_id: this.bank_order_id,
      };
      axios
        .post(url, propsItem)
        .then((response) => {
          this.errorMessage = false;
          const item = response.data
          if(item.payment_type_id && item.payment_type_id == 17){
            const props = {
              amount: parseInt(item.amount),
              client_id: item.client_id || 0,
              payment_type_id: item.payment_type_id,
              store_id: 1,
              bank_order_id: this.bank_order_id,
            }
            this.createPayment(props)
          }
        })
        .catch((error) => {
          this.errorMessage = true;
            if (error.response) {
              alert(1)
              if (error.response.status === 400 || error.response.status === 500) {
                  if (error.response.data && error.response.data.error) {
                    this.errorAlert = error.response.data.error;
                  }
              }
            }
        });
    },
    createPayment(props){
      const url = `${process.env.VUE_APP_DEV_SERVER_PROXY}/payments`;
          axios
            .post(url, props)
            .then((res) => {
              console.log(res)
            })
            .catch((error) => {
              console.log(error);
              
            });
    },
    getCouriers() {
      const url = `${process.env.VUE_APP_DEV_SERVER_PROXY}/users`;

      axios
        .get(url, {
          params: {
            group_id: 15,
          },
        })
        .then((response) => {
          this.couriersList = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getUsers() {
      const loadData = this.loadingData.find(item => item.id === 'managers');
      const url = `${process.env.VUE_APP_DEV_SERVER_PROXY}/users`;

      axios
        .get(url, {
          params: { group_id: 14}
        })
        .then((response) => {
          const items = response.data;
          this.usersList = items;

          loadData.title = 'Менеджеры получены!';
          loadData.loading = false;
        })
        .catch((error) => {
          loadData.title = 'Ошибка получения менеджеров!';
          loadData.error = true;
          console.log(error);
        });
    },
    // getDeliveryNow() {
    //   const orderFilter = {
    //     deliveryDate: [this.dateNowStr, this.dateNowStr],
    //     deliveryType: 2,
    //   };

    //   const itemParams = {
    //     type: 'orders',
    //     sort: {
    //       deliveryDate: 'desc',
    //     },
    //     filter: orderFilter,
    //   };

    //   this.$store.dispatch('getItemsList', itemParams).then((response) => {
    //     this.deliveryNow = response.orders.length;
    //   }).catch(() => {
    //     console.log('error');
    //   });
    // },
    // getStatusList() {
    //   const itemParams = {
    //     type: 'order-status',
    //   };

    //   this.$store.dispatch('getItemsList', itemParams).then((response) => {
    //     this.statusList = response.map((item) => {
    //       item.id = +item.id;
    //       return item;
    //     });
    //   }).catch(() => {
    //     console.log('error');
    //   });
    // },
    // getClientsList() {
    //   const itemParams = {
    //     type: 'clients',
    //     filter: {
    //       active: true,
    //     },
    //   };

    //   this.$store.dispatch('getItemsList', itemParams).then((response) => {
    //     this.clientsList = response.map((item) => {
    //       item.id = +item.id;
    //       return item;
    //     });
    //   }).catch(() => {
    //     console.log('error');
    //   });
    // },
    // getClientTypeList() {
    //   const itemParams = {
    //     type: 'client-type',
    //   };

    //   this.$store.dispatch('getItemsList', itemParams).then((response) => {
    //     this.typeClient = response.map((item) => {
    //       item.id = +item.id;
    //       return item;
    //     });
    //   }).catch(() => {
    //     console.log('error');
    //   });
    // },
    closeDialog() {
      this.getOrdersList();
      this.copyElem = false;
      this.showMap = false;
      this.editOrderBouquets = false;
      this.dialogForm = false;
      this.orderSelect = {};
    },
    changeStatus(id) {
      this.$store.commit('setEditedOrderId', +id);
      this.$store.commit('setEditStatus', true);
      this.$store.commit('setShowDialogOrderEdit', true);
    },
    viewOrder(id) {
      this.resetDialogStates();
      this.orderSelect = this.ordersList.find(item => item.id === id);
      this.showOrder = true;
      this.dialogForm = true;
    },
    editBouquets(id) {
      this.resetDialogStates();
      this.orderSelect = this.ordersList.find(item => item.id === id);
      this.editOrderBouquets = true;
      this.dialogForm = true;
    },
    changeBouquets(id) {
      this.resetDialogStates();
      this.$store.commit('setEditedOrderId', +id);
      this.$store.commit('setEditOrderBouquets', true);
      this.$store.commit('setShowDialogOrderEdit', true);
    },
    viewMap(id) {
      this.resetDialogStates();
      this.orderSelect = this.ordersList.find(item => item.id === id);
      this.showMap = true;
      this.dialogForm = true;
      // push again
    },
    zoomOrderMap(coordinates) {
      console.log(coordinates);
      const coordinatesArr = coordinates.split(',');

      if (coordinatesArr && coordinatesArr.length === 2) {
        this.zoomMap = 15;
        setTimeout(() => {
          this.coordsMap = coordinatesArr;
        }, 300);
      }
    },
    resetDialogStates() {
      this.showOrder = false;
      this.showMap = false;
      this.editOrderBouquets = false;
    },
    changeSort(column) {
      if (this.pagination.sortBy === column) {
        this.pagination.descending = !this.pagination.descending;
      } else {
        this.pagination.sortBy = column;
        this.pagination.descending = false;
      }
    },
    getDeliveries() {
      const itemParams = {
        type: 'deliveries',
      };

      this.$store.dispatch('getItemsList', itemParams).then((response) => {
        console.log(response);
      });
    },
    logout() {
      this.$store.dispatch('logout').then(() => {
        this.$router.push('/login');
      });
    },
    setFilterProp(code, value) {
      if(code == "courier_id" && value){
        this.$set(this.filter, 'order_status_ids', [3]);
        this.filter.order_status_ids = this.filter.order_status_ids.join(',');
      } else {
        this.$set(this.filter, 'order_status_ids', [1, 2, 3, 7]);
        this.filter.order_status_ids = this.filter.order_status_ids.join(',');
      }
      this.filter[code] = value;
      this.getOrdersList();
    },
    setCoordsMap() {
      // this.coordsMap = e.get('coords');
    },
  },
  mounted() {
    this.filter.order_status_ids = this.filter.order_status_ids.join(',');
    window.addEventListener('resize', this.updateWidthWindow);
    this.widthWindow = window.innerWidth;

    const date = new Date();
    const dateNowStr = date.toISOString().split('T')[0];
    this.dateNowStr = dateNowStr;

    const dateStart = dateNowStr;

    // date.setDate(date.getDate() + 7);
    // const dateEnd = date.toISOString().split('T')[0];

    

    this.filter.start_date = dateStart;
    this.filter.end_date = dateStart;
    // this.take = this.$store.getters.getCountElemPage;
    if(this.$route.query.orderId){
      this.bank_order_id = this.$route.query.orderId;
      this.getBankStatus();
    }
    this.getStatus();
    this.getClientTypes();
    this.getDeliveryTypes();
    this.getOrdersSource();
    this.getCouriers();
    this.getOrdersList();
    this.getUsers();
  },
};
</script>

<style land="scss">
  .orders-table {
    max-height: calc(100vh - 104px - 21px - 36px - 42px);
    overflow: auto;
  }

  .orders-table .theme--light.v-table tbody tr:not(:last-child).top-line {
    border-top: 4px solid #404040!important;
  }
  .orders-table .theme--light.v-table tbody tr:not(:last-child) {
    border-bottom: none;
    border-top: 1px solid #9c9c9c!important;
  }
  .orders-table table.v-table thead th:first-child,
  .orders-table table.v-table thead th:not(:first-child) {
    padding: 0 4px;
  }
  .orders-table table.v-table tbody td:first-child,
  .orders-table table.v-table tbody td:not(:first-child),
  .orders-table table.v-table tbody th:first-child,
  .orders-table table.v-table tbody th:not(:first-child),
  .orders-table table.v-table thead td:first-child,
  .orders-table table.v-table thead td:not(:first-child),
  .orders-table table.v-table thead th:first-child,
  .orders-table table.v-table thead th:not(:first-child) {
    border-right: 1px solid rgba(0,0,0,.12);
  }

  .theme--light.v-table thead tr:first-child {
    border-top: 1px solid rgba(0,0,0,.12);
  }

  .orders-table .v-datatable thead th.column.sortable .v-icon {
    display: block;
    width: 16px;
  }
</style>
